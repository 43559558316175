import React from 'react';

//styles
import * as S from './styles';
import * as L from 'layout-styled-components';
import { Paper } from '@material-ui/core';
import { flex } from 'layout-styled-components';

const Column: React.FC<{ hasPadding?: boolean }> = ({
  children,
  hasPadding = true
}) => {
  return (
    <Paper
      style={{
        flex: 1,
        ...flex.vertical,
        height: '100%',
        maxHeight: '100%',
        overflow: 'hidden'
      }}
    >
      <div
        style={{
          ...flex.vertical,
          ...(hasPadding && { padding: 15 }),
          flex: 1,
          height: 300,
          overflow: 'auto'
        }}
      >
        {children}
      </div>
    </Paper>
  );
};

export default Column;
