import React from 'react';
import { deburr, take } from 'lodash';
import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import { Dialog, makeStyles } from '@material-ui/core';

import { useOvermind } from 'store';
import { searchBlocks } from 'store/helpers';

const useStyles = makeStyles(theme => ({
  scrollPaper: {
    minHeight: '90vh'
  },
  menuItem: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      style={{ padding: 15 }}
      autoFocus
      InputProps={{
        inputRef: ref,
        ...InputProps
      }}
      {...other}
    />
  );
}

function renderBlock({ block, classes, index, itemProps, highlightedIndex }) {
  highlightedIndex = highlightedIndex === null ? 0 : highlightedIndex;
  const isHighlighted = highlightedIndex === index;

  return (
    <MenuItem
      classes={{
        root: classes.menuItem
      }}
      style={{ whiteSpace: 'normal' }}
      {...itemProps}
      key={block.id}
      selected={isHighlighted}
      component="div"
    >
      {block.message}
    </MenuItem>
  );
}

function getSuggestions(allBlocks, chosenBlocks, search) {
  const inputValue = deburr(search.trim()).toLowerCase();

  return searchBlocks({
    blocks: allBlocks,
    chosenBlocks: chosenBlocks,
    search: inputValue
  });
}

const ChooseBlockModal: React.FC<{ open?: boolean; onClose?: any }> = ({
  open,
  onClose
}) => {
  const classes = useStyles({});
  const { state, actions } = useOvermind();
  const { allBlocks, chosenBlocks } = state;

  return (
    <Dialog
      disableEnforceFocus
      classes={{
        paper: classes.scrollPaper
      }}
      fullWidth
      maxWidth="xl"
      onClose={onClose}
      open={open}
    >
      <div>
        <Downshift
          id="downshift-options"
          onSelect={blockId => {
            actions.addChosenBlockById(blockId);
          }}
        >
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            openMenu
          }) => {
            //@ts-ignore
            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
              onFocus: openMenu,
              placeholder: 'Search...'
            });

            let suggestions = take(
              getSuggestions(allBlocks, chosenBlocks, inputValue),
              25
            );

            return (
              <div>
                {renderInput({
                  fullWidth: true,
                  //@ts-ignore
                  InputLabelProps: getLabelProps({ shrink: true }),
                  InputProps: { onBlur, onChange, onFocus },
                  inputProps,
                  onKeyDown: event => {
                    if (event.key === 'Enter' && highlightedIndex === null) {
                      if (suggestions.length > 0) {
                        event.nativeEvent.preventDownshiftDefault = true;
                        actions.addChosenBlockById(suggestions[0].id);
                      }
                    }
                  }
                })}

                <div {...getMenuProps()}>
                  <Paper square>
                    {suggestions.map((block, index) =>
                      renderBlock({
                        block,
                        classes,
                        index,
                        itemProps: getItemProps({ item: block.id }),
                        highlightedIndex
                      })
                    )}
                  </Paper>
                </div>
              </div>
            );
          }}
        </Downshift>
      </div>
    </Dialog>
  );
};

export default ChooseBlockModal;
