import { LazyApp, users } from 'config/users';
import { variableRegex } from 'config/variable-regex';
import { copyToClipboard } from 'copy-lite';
import { Action, AsyncAction } from 'overmind';
import { replaceBlockVariables } from 'store/helpers';
import { Block, ChosenBlock, Template, Variable } from 'store/state';
import { getRegexMatches, insertIntoArray } from 'utils/utils';
import uuid from 'uuid/v4';

const getBlockVariables = (message: string): Variable[] => {
  const variables: Variable[] = [];

  getRegexMatches(message, variableRegex, match => {
    const variable = match[1];
    variables.push({
      id: variable,
      label: variable
    });
  });

  return variables;
};

export const addChosenBlock = ({ state, actions, effects }, block: Block) => {
  if (block.variables && block.variables.length > 0) {
    state.insertingBlock = { ...block };
    state.dialogs.chooseBlock = false;
  } else {
    actions.insertChosenBlock(block.id);
  }
};

export const addChosenBlockById = (
  { state, actions, effects },
  blockId: string
) => {
  const foundBlock = state.allBlocks.find(b => b.id === blockId);
  if (foundBlock) {
    actions.addChosenBlock(foundBlock);
  }
};

export const insertChosenBlock = async (
  { state, effects, actions },
  blockId
) => {
  let chosenBlock: ChosenBlock = {
    id: uuid(),
    blockId,
    values: state.blockFormValues
  };

  state.chosenBlocks.push(chosenBlock);
  state.insertingBlock = null;
  state.dialogs.chooseBlock = false;
  state.search = '';
  actions.clearBlockForm();
};

export const removeChosenBlock = ({ state, effects }, block: ChosenBlock) => {
  state.chosenBlocks = state.chosenBlocks.filter(b => b.id !== block.id);
};

export const editChosenBlock = ({ state, effects }, block: ChosenBlock) => {
  const foundBlock = state.allBlocks.find(b => b.id === block.blockId);
  state.blockForm = block.values.reduce((o, v) => {
    o[v.id] = v.value;
    return o;
  }, {});
  state.editingBlock = { ...block };
  state.insertingBlock = { ...foundBlock };
};

export const editEntireChosenBlock = async (
  { state, effects },
  block: ChosenBlock
) => {
  const foundBlock = state.allBlocks.find(b => b.id === block.blockId);
  const chosenBlockIndex = state.chosenBlocks.findIndex(b => block.id === b.id);
  const blockMessage = replaceBlockVariables(block, foundBlock, block.values);
  const message = await effects.prompt('Edit block', blockMessage);
  if (message) {
    state.chosenBlocks[chosenBlockIndex] = {
      ...foundBlock,
      values: [],
      blockId: null,
      message
    };
  }
};

export const editOriginalBlock = async (
  { state, effects },
  block: ChosenBlock
) => {
  const foundBlock: Block = state.allBlocks.find(b => b.id === block.blockId);
  state.editingBlockInDialog = { ...foundBlock };
};

export const updateChosenBlock = ({ state, effects, actions }, id: string) => {
  const foundBlock = state.chosenBlocks.find(b => id === b.id);
  const foundBlockIndex = state.chosenBlocks.findIndex(b => id === b.id);
  state.chosenBlocks[foundBlockIndex] = {
    ...foundBlock,
    values: state.blockFormValues
  };
  actions.cancelBlock();
};

export const setSearch = ({ state, effects }, e) => {
  state.search = e.target.value;
};

export const toggleSetting = ({ state }, name) => {
  state.settings[name] = !state.settings[name];
};

export const updateChosenBlocks = ({ state }, newBlocks) => {
  state.chosenBlocks = newBlocks;
};

export const toggleDialog = ({ state }, dialogKey) => {
  state.dialogs[dialogKey] = !state.dialogs[dialogKey];
};

export const clear = ({ state }) => {
  state.chosenBlocks = [];
};

export const cancelBlock = ({ state, actions }) => {
  state.insertingBlock = null;
  state.editingBlock = null;
  actions.clearBlockForm();
};

export const setBlockFormValue = ({ state }, { id, value }) => {
  state.blockForm[id] = value;
};

export const clearBlockForm = ({ state }) => {
  state.blockForm = {};
};

export const deleteBlock = async ({ state, effects }, block: Block) => {
  const confirmed = await effects.confirm();
  if (confirmed) {
    state.allBlocks = state.allBlocks.filter(b => b.id !== block.id);
    effects.updateData(state);
  }
};

export const editBlockInDialog = ({ state }, block: Block) => {
  state.editingBlockInDialog = { ...block };
};

export const editBlock: AsyncAction<{
  blockId: string;
  message?: string;
}> = async ({ state, effects }, props) => {
  const { blockId, message } = props;
  const foundBlockIndex = state.allBlocks.findIndex(b => blockId === b.id);

  state.allBlocks[foundBlockIndex].message = message;
  state.allBlocks[foundBlockIndex].variables = getBlockVariables(message);
  state.editingBlockInDialog = null;
  effects.updateData(state);
};

export const setStore = ({ state }, { allBlocks, templates }) => {
  state.allBlocks = allBlocks;
  state.templates = templates;
};

export const initiateCreateBlock = ({ state }) => {
  state.creatingBlock = true;
};

export const addBlock: AsyncAction<{ message: string }> = async (
  { state, actions, effects },
  props
) => {
  const { message } = props;

  const newBlock: Block = {
    id: uuid(),
    message,
    variables: getBlockVariables(message)
  };

  state.allBlocks.push(newBlock);
  actions.addChosenBlock(newBlock);
  state.creatingBlock = false;
  effects.updateData(state);
};

export const chooseTemplate = (
  { state, actions, effects },
  template: Template
) => {
  state.chosenBlocks = [...template.blocks];
  actions.closeChooseTemplateDialog();
};

export const saveTemplate = async ({ state, effects }) => {
  const title = await effects.prompt('Template name');
  if (title) {
    const newTemplate: Template = {
      id: uuid(),
      title,
      blocks: [...state.chosenBlocks]
    };

    state.templates.push(newTemplate);
    effects.updateData(state);
  }
};

export const selectTemplate = ({ state, effects }, template: Template) => {
  state.selectedTemplate = { ...template };
};

export const deleteChosenTemplate = async ({ state, effects }) => {
  const confirmed = await effects.confirm();
  if (confirmed) {
    state.templates = state.templates.filter(
      b => b.id !== state.selectedTemplate.id
    );
    state.selectedTemplate = null;
    effects.updateData(state);
  }
};

export const clearChosenTemplate = ({ state }) => {
  state.selectedTemplate = null;
};

export const editTemplate = async ({ state, effects }) => {
  const template = state.selectedTemplate;
  const newTitle = await effects.prompt('Edit template title', template.title);
  if (newTitle) {
    const foundTemplateIndex = state.templates.findIndex(
      b => template.id === b.id
    );
    let foundTemplate = state.templates[foundTemplateIndex];
    state.templates[foundTemplateIndex] = { ...foundTemplate, title: newTitle };
    effects.updateData(state);
  }
};

export const chooseTemplateToUpdate = (
  { state, effects },
  template: Template
) => {
  const foundTemplateIndex = state.templates.findIndex(
    b => template.id === b.id
  );
  state.templates[foundTemplateIndex].blocks = [...state.chosenBlocks];
  state.dialogs.updateTemplate = false;
  effects.updateData(state);
};

export const closeChooseTemplateDialog = ({ state }) => {
  state.dialogs.chooseTemplate = false;
  state.selectedTemplate = null;
  state.searchTemplates = '';
};

export const insertChosenTemplateInMissive = (
  { state, effects, actions },
  {message, reply},
) => {
  //@ts-ignore
  effects.insertInMissive(message, reply);
  actions.closeChooseTemplateDialog();
};

export const insertAfterBlock = async (
  { state, effects },
  { block, message }: { block: ChosenBlock; message?: string }
) => {
  if (!message) {
    message = await effects.prompt('Enter message');
  }
  if (message) {
    const foundBlockIndex = state.chosenBlocks.findIndex(
      b => block.id === b.id
    );
    let newChosenBlock: ChosenBlock = {
      id: uuid(),
      message
    };
    insertIntoArray(state.chosenBlocks, foundBlockIndex + 1, newChosenBlock);
  }
};

export const copyTemplate = ({ state, actions, effects }, finalMessage) => {
  copyToClipboard(finalMessage, true);
  actions.closeChooseTemplateDialog();
};

export const copyEmail = ({ state }) => {
  copyToClipboard(state.finalMessage, true);
  state.dialogs.finalMessage = false;
};

export const updateVariableInTemplate = ({ state }, { id, value }) => {
  const foundBlock = state.selectedTemplateVariables.find(b => b.id === id);
  if (foundBlock) {
    foundBlock.value = value;
  }
};

export const cancelChooseBlock = ({ state }) => {
  state.dialogs.chooseBlock = false;
  state.search = '';
};

export const cancelEditingBlock = ({ state }) => {
  state.editingBlockInDialog = null;
  state.creatingBlock = false;
};

let searchRef;

export const focusSearch = () => {
  searchRef && searchRef.focus();
};

export const setSearchRef = ({ state }, ref) => {
  searchRef = ref;
};

export const setSearchTemplates = ({ state }, value) => {
  state.searchTemplates = value;
};

export const toggleDrawer = ({ state }) => {
  state.drawer = !state.drawer;
};

export const openAndSelectTemplate = ({ state }, templateId) => {
  const foundTemplate = state.templates.find(t => t.id === templateId);
  if (foundTemplate) {
    state.dialogs.chooseTemplate = true;
    state.selectedTemplate = { ...foundTemplate };
  }
};

export const copyLinkToTemplate = ({ state }, template: Template) => {
  copyToClipboard(`${window.location.origin}/template/${template.id}`);
};

export const toggleTemplateFavorite: Action<Template> = (
  { state, effects },
  template: Template
) => {
  const foundTemplateIndex = state.templates.findIndex(
    b => template.id === b.id
  );
  let foundTemplate = state.templates[foundTemplateIndex];

  state.templates[foundTemplateIndex] = {
    ...foundTemplate,
    favorite: !(foundTemplate.favorite === true)
  };

  effects.updateData(state);
};

export const showPreviewDialog: Action<string> = (
  { state, actions },
  previewText: string
) => {
  state.previewText = previewText;
  actions.toggleDialog('finalMessage');
};

export const login: Action<{ username: string; password: string }> = (
  { state, actions },
  { username, password }
) => {
  const foundUser = users.find(u => u.username === username);
  if (!foundUser) {
    return alert('no user found');
  }
  if (foundUser.password !== password) {
    return alert('password not correct');
  }
  state.user = foundUser;
  window.localStorage.setItem('username', foundUser.username);
  if (foundUser.apps.length === 1) {
    let firstApp = foundUser.apps[0];
    window.localStorage.setItem('appId', firstApp.id);
    state.app = firstApp;
  }
};

export const setApp: Action<LazyApp> = ({ state }, app) => {
  window.localStorage.setItem('appId', app.id);
  state.app = { ...app };
};

export const setSpaceHtml: Action<string> = ({ state }, spaceHtml) => {
  state.settings.spaceHtml = spaceHtml;
};

export const logout: Action = ({ state }) => {
  state.user = null;
  state.app = null;
  state.templates = [];
  state.allBlocks = [];
  state.chosenBlocks = [];
  window.localStorage.removeItem('username');
  window.localStorage.removeItem('appId');
};

export const switchApps: Action = ({ state }) => {
  state.app = null;
  state.templates = [];
  state.allBlocks = [];
  state.chosenBlocks = [];
  window.localStorage.removeItem('appId');
};
