import React from 'react';
import { take } from 'lodash';

//styles
import * as S from './styles';
import * as A from 'styles/shared-components';
import * as L from 'layout-styled-components';

//material-ui
import { List } from '@material-ui/core';

import { useOvermind } from 'store';
import Block from 'components/Block';
import Column from 'components/Column';

const AllBlocks: React.FC<{ allowEdit?: boolean; asButton?: boolean }> = ({
  allowEdit,
  asButton
}) => {

  const { state } = useOvermind();
  const { filteredBlocks, settings } = state;

  const blocks = settings.limitBlocks
    ? take(filteredBlocks, 35)
    : filteredBlocks;

  return (
    <Column hasPadding={false}>
      <List>
        {blocks.map((block, index) => (
          <Block
            allowEdit={allowEdit}
            asButton={asButton}
            showDivider={index !== blocks.length - 1}
            key={block.id}
            block={block}
          />
        ))}
      </List>
    </Column>
  );
};

export default AllBlocks;
