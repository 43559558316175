import React from 'react';
import * as L from 'layout-styled-components';
import { IconButton, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

//@ts-ignore
const ModalHeader = props => {
  const { children, onClose } = props;
  return (
    <DialogTitle disableTypography>
      <L.Horizontal centerV spaceBetween>
        <div style={{ fontWeight: 500, flex: 1, fontSize: 18 }}>{children}</div>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </L.Horizontal>
    </DialogTitle>
  );
};

export default ModalHeader;
