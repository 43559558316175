type JsonBinConfig = {
  url: string;
  headers: {
    'X-Master-Key': string;
  };
};

export type LazyApp = {
  id: string;
  name: string;
  jsonBinConfig: JsonBinConfig;
};

const SECRET = '$2b$10$AI0M4S2TxYCBoBbUeAb01.COaaJ2a5z/QwU2gaT3MXBX/CAIKeuxC';

export type LazyUser = {
  name: string;
  username: string;
  email: string;
  password: string;
  apps: LazyApp[];
};

let sizzyEmail = {
  id:'sizzy-email',
  name: 'Sizzy Email',
  jsonBinConfig: {
    url: process.env.REACT_APP_SIZZY_APP_URL,
    headers: {
      'X-Master-Key': SECRET
    }
  }
};

let testApp = {
  name: 'Lazy Test',
  id: 'test',
  jsonBinConfig: {
    url: process.env.REACT_APP_TEST_APP_URL,
    headers: {
      'X-Master-Key': SECRET
    }
  }
};

export const apps = [sizzyEmail, testApp]

export const users = [
  {
    name: 'Kitze',
    username: 'kitze',
    email: 'kristijan.mkd@gmail.com',
    password: process.env.REACT_APP_KITZE_PASSWORD,
    apps: [sizzyEmail, testApp]
  },
  {
    name: 'Marija',
    username: 'marija',
    email: 'marija.stamadzieva@gmail.com',
    password: process.env.REACT_APP_MARIJA_PASSWORD,
    apps: [sizzyEmail]
  }
];
