//material-ui
import {
  AppBar,
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  Toolbar
} from '@material-ui/core';

import { Add as PlusIcon, Email } from '@material-ui/icons';
import arrayMove from 'array-move';
import axios from 'axios';
//components
import AllBlocks from 'components/AllBlocks';
import ChosenBlocks from 'components/ChosenBlocks';
import Column from 'components/Column';
import FinalMessage from 'components/FinalMessage';
import Modals from 'components/Modals';
import routes from 'config/routes';
import { copyToClipboard } from 'copy-lite';
import * as L from 'layout-styled-components';
import { json } from 'overmind';
import React, { useEffect, useRef } from 'react';
import { hot } from 'react-hot-loader/root';
import { useHotkeys } from 'react-hotkeys-hook';
import { useRouter } from 'react-tiniest-router';
import { useOvermind } from 'store';
import { insertInMissive, printMissiveLog } from 'store/effects';
import { hotkey, useLoadScript } from 'utils/utils';
//styles
import * as S from './styles';

function App() {
  const { state, actions } = useOvermind();
  const { routeId, goTo, params } = useRouter();

  const {
    finalMessage,
    settings,
    search,
    hasChosenBlocks,
    chosenBlocks,
    isMobile,
    templates,
    app
  } = state;

  useEffect(() => {
    if (routeId === routes.template.id && templates.length > 0) {
      actions.openAndSelectTemplate(params.templateId);
      goTo(routes.home);
    }
  }, [routeId, params.templateId, templates.length]);

  const textField = useRef(null);

  let focusSearch = () => {
    textField.current && textField.current.focus();
  };

  useHotkeys('f', hotkey(focusSearch));
  useHotkeys('s', hotkey(focusSearch));
  useHotkeys('cmd+e', () => actions.toggleDialog('chooseTemplate'));
  useHotkeys('cmd+b', () => actions.toggleDialog('chooseBlock'));

  let initialFetchData = async () => {
    if (app) {
      let config = {
        method: 'get',
        ...json(app.jsonBinConfig),
        url: `${app.jsonBinConfig.url}`
      };
      //@ts-ignore
      const { data: {record} } = await axios(config);
      actions.setStore(record);
    }
  };

  useEffect(() => {
    initialFetchData();
  }, []);

  useEffect(() => {
    const toPersist = JSON.stringify(json(settings));
    window.localStorage.setItem('settings', toPersist);
  }, [settings]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    actions.updateChosenBlocks(arrayMove(chosenBlocks, oldIndex, newIndex));
  };

  let previewButton = (
    <Button
      disabled={!hasChosenBlocks}
      onClick={() => actions.showPreviewDialog(finalMessage)}
      size="small"
      color="primary"
      variant="contained"
    >
      Preview
    </Button>
  );
  let showBlocksToSelect = !settings.useDialogForBlocks && !isMobile;
  let actionButtons = (
    <>
      {
        <Button
          disabled={true}
          onClick={() => copyToClipboard(finalMessage, true)}
          size="small"
          color="primary"
          variant="contained"
        >
          Copy
        </Button>
      }
      <Button
        disabled={!hasChosenBlocks}
        onClick={() => insertInMissive(finalMessage)}
        size="small"
        color="primary"
        variant="contained"
      >
        Insert
      </Button>
      <Button
        disabled={!hasChosenBlocks}
        onClick={() => insertInMissive(finalMessage, true)}
        size="small"
        color="primary"
        variant="contained"
      >
        Reply
      </Button>
      {previewButton}
    </>
  );
  return (
    <S.App>
      <AppBar position="static">
        <Toolbar>
          <L.Horizontal centerV fullW spaceBetween>
            <div style={{ fontWeight: 500, fontSize: 18 }}>LH</div>

            <L.Horizontal spaceAll={15}>
              {!isMobile && (
                <>
                  <Button
                    onClick={() => actions.toggleDialog('chooseTemplate')}
                    size="small"
                    variant="contained"
                  >
                    Templates
                  </Button>
                  <Button
                    onClick={actions.initiateCreateBlock}
                    size="small"
                    variant="contained"
                  >
                    Create block
                  </Button>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!state.settings.limitBlocks}
                        onChange={() => actions.toggleSetting('limitBlocks')}
                        value={!state.settings.limitBlocks}
                        color="secondary"
                      />
                    }
                    label={`Show ${state.allBlocks.length} Blocks`}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.settings.inverseBlocks}
                        value={state.settings.inverseBlocks}
                        onChange={() => actions.toggleSetting('inverseBlocks')}
                        color="secondary"
                      />
                    }
                    label={`Inverse Blocks`}
                  />
                </>
              )}

              {isMobile && (
                <>
                  <IconButton
                    onClick={() => actions.toggleDialog('chooseTemplate')}
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                  >
                    <Email />
                  </IconButton>
                  <IconButton
                    onClick={actions.initiateCreateBlock}
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                  >
                    <PlusIcon />
                  </IconButton>
                </>
              )}

              <Button
                onClick={actions.logout}
                size="small"
                color="secondary"
                variant="contained"
              >
                Log out
              </Button>
              {state.user.apps.length > 1 && (
                <Button
                  onClick={actions.switchApps}
                  size="small"
                  color="secondary"
                  variant="contained"
                >
                  Switch
                </Button>
              )}
            </L.Horizontal>
          </L.Horizontal>
        </Toolbar>
      </AppBar>
      <S.Wrap>
        <L.Horizontal fullH flex={1} spaceAll={15}>
          {showBlocksToSelect && (
            <L.Vertical style={{ flexShrink: 0 }} spaceAll={15} flex={1} fullH>
              <S.ColumnHeader>
                <S.Search
                  ref={textField}
                  placeholder="Search..."
                  value={search}
                  onChange={actions.setSearch}
                  type="text"
                />
              </S.ColumnHeader>
              <AllBlocks />
            </L.Vertical>
          )}
          <L.Vertical
            style={{
              flexShrink: 0,
              ...(settings.useDialogForBlocks && {
                maxWidth: 450
              })
            }}
            spaceAll={15}
            flex={1}
          >
            <S.ColumnHeader>
              <Button
                disabled={!hasChosenBlocks}
                onClick={actions.clear}
                size="small"
                color="secondary"
                variant="contained"
              >
                Clear
              </Button>
              <Button
                disabled={!hasChosenBlocks}
                onClick={actions.saveTemplate}
                size="small"
                color="primary"
                variant="contained"
              >
                {isMobile ? 'Save' : 'Save As'}
              </Button>
              <Button
                disabled={!hasChosenBlocks}
                onClick={() => actions.toggleDialog('updateTemplate')}
                size="small"
                color="primary"
                variant="contained"
              >
                {isMobile ? 'Update' : 'Update template'}
              </Button>
              {isMobile && actionButtons}
            </S.ColumnHeader>

            <ChosenBlocks
              helperClass="SortableHelperWithOverride"
              useDragHandle={true}
              lockAxis="y"
              onSortEnd={onSortEnd}
            />
          </L.Vertical>
          {!isMobile && (
            <L.Vertical style={{ flexShrink: 0 }} flex={1} spaceAll={15}>
              <S.ColumnHeader justifyEnd>{actionButtons}</S.ColumnHeader>
              {/*  <TextField
                placeholder="Spacer"
                value={state.settings.spaceHtml}
                onChange={e => actions.setSpaceHtml(e.target.value)}
              />*/}
              <Column>
                <FinalMessage finalMessage={finalMessage} />
              </Column>
            </L.Vertical>
          )}
        </L.Horizontal>
      </S.Wrap>
      <Modals />
    </S.App>
  );
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
