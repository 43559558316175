import styled from 'styled-components';

export type GridProps = {
  minColumnWidth?: number;
  gridGap?: number;
  mode?: string;
  maxColumnWidth?: string;
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
};
export const autoGrid = ({
  minColumnWidth = 250,
  gridGap = 0,
  mode = 'fill',
  maxColumnWidth = '1fr',
  gridTemplateColumns = `repeat(auto-${mode}, minmax(${minColumnWidth}px, ${maxColumnWidth}))`,
  gridTemplateRows
}: GridProps) => ({
  gridTemplateColumns,
  gridTemplateRows,
  display: 'grid',
  gridGap
});

export const Space = styled.div({});

export const Grid = styled.div<GridProps>(p => autoGrid(p));
