import { sanitizeHtmlConfig } from 'config/sanitize-html-config';
import React, { useMemo } from 'react';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import SanitizedHTML from 'react-sanitized-html';

//styles
import * as S from './styles';
import * as L from 'layout-styled-components';
import { ChosenBlock as TChosenBlock } from 'store/state';
import { useOvermind } from 'store';
import { replaceBlockVariables } from 'store/helpers';

//material-ui
import ListItemText from '@material-ui/core/ListItemText';
import { Divider, ListItem, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {
  Edit,
  Delete,
  More,
  RotateLeft,
  DragHandle as DragHandleIcon
} from '@material-ui/icons';

const DragHandle = SortableHandle(({ children }) => {
  if (children) {
    return children;
  }

  return (
    <IconButton size="small">
      <DragHandleIcon fontSize="small" />
    </IconButton>
  );
});

const ChosenBlock: React.FC<{ block: TChosenBlock; index?: number }> = ({
  block
}) => {
  const { actions, state } = useOvermind();
  const { allBlocks } = state;
  const foundBlock = allBlocks.find(b => b.id === block.blockId);

  let chosenBlockText = useMemo(
    () => replaceBlockVariables(block, foundBlock, block.values),
    [block.values]
  );

  let hasVariables =
    foundBlock && foundBlock.variables && foundBlock.variables.length > 0;

  const onIcon = fn => e => {
    e.preventDefault();
    e.stopPropagation();
    fn();
  };

  return (
    <S.ChosenBlock>
      <ListItem style={{ minHeight: 60 }}>
        <L.Vertical fullW>
          <L.Vertical fullW>
            <L.Horizontal fullW flex={1} centerV>
              <DragHandle>
                <div style={{ flex: 1 }}>
                  <ListItemText
                    className="dense"
                    primary={
                      <SanitizedHTML
                        {...sanitizeHtmlConfig}
                        html={chosenBlockText}
                      />
                    }
                  />
                </div>
              </DragHandle>

              <Tooltip placement="top" title="Delete chosen block">
                <IconButton
                  size="small"
                  onClick={onIcon(() => actions.removeChosenBlock(block))}
                  edge="end"
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Tooltip>

              {hasVariables && (
                <Tooltip placement="top" title="Edit block variables">
                  <IconButton
                    size="small"
                    onClick={onIcon(() => actions.editChosenBlock(block))}
                    edge="end"
                  >
                    <More fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}

              {block.blockId && (
                <Tooltip placement="top" title="Edit original block">
                  <IconButton
                    size="small"
                    onClick={onIcon(() => actions.editOriginalBlock(block))}
                    edge="end"
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip placement="top" title="Replace block with custom text">
                <IconButton
                  size="small"
                  onClick={onIcon(() => actions.editEntireChosenBlock(block))}
                  edge="end"
                >
                  <RotateLeft fontSize="small" />
                </IconButton>
              </Tooltip>
            </L.Horizontal>
          </L.Vertical>
        </L.Vertical>
      </ListItem>
      <Divider />
    </S.ChosenBlock>
  );
};

export default SortableElement(ChosenBlock);
