import EditBlockModal from 'components/EditBlockModal';
import React from 'react';

//styles
import * as S from './styles';
import * as L from 'layout-styled-components';
import { useOvermind } from 'store';

//modals
import ChooseTemplateModal from 'components/ChooseTemplateModal';
import ChooseBlockModal from 'components/ChooseBlockModal';
import InsertBlockModal from 'components/InsertBlockModal';
import UpdateTemplateModal from 'components/UpdateTemplateModal';
import FinalMessageModal from 'components/FinalMessageModal';

const Modals: React.FC = () => {
  const { state, actions } = useOvermind();
  const { dialogs } = state;

  const showEditBlockModal =
    !!state.editingBlockInDialog || state.creatingBlock;

  return (
    <L.Horizontal>
      <ChooseTemplateModal
        onClose={actions.closeChooseTemplateDialog}
        open={dialogs.chooseTemplate}
      />

      <ChooseBlockModal
        onClose={actions.cancelChooseBlock}
        open={dialogs.chooseBlock}
      />

      {showEditBlockModal && (
        <EditBlockModal onClose={actions.cancelEditingBlock} open={true} />
      )}

      <UpdateTemplateModal
        onClose={() => actions.toggleDialog('updateTemplate')}
        open={dialogs.updateTemplate}
      />
      <InsertBlockModal
        open={!!state.insertingBlock}
        onClose={actions.cancelBlock}
      />
      <FinalMessageModal
        open={dialogs.finalMessage}
        onClose={() => actions.toggleDialog('finalMessage')}
      />
    </L.Horizontal>
  );
};

export default Modals;
