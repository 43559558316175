import React from 'react';

import * as L from 'layout-styled-components';

import {
  Dialog,
  ListItemText,
  ListItem,
  Divider,
  List,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';

import { useOvermind } from 'store';
import ModalHeader from 'components/ModalHeader';
import FinalMessage from 'components/FinalMessage';

const FinalMessageModal: React.FC<{ open?: boolean; onClose?: any }> = ({
  open,
  onClose
}) => {
  const { state, actions } = useOvermind();
  const { previewText } = state;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      onClose={onClose}
      open={open}
    >
      <ModalHeader id="customized-dialog-title" onClose={onClose}>
        Preview email
      </ModalHeader>
      <DialogContent>
        <DialogContentText>
          <FinalMessage finalMessage={previewText} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.copyEmail} color="primary">
          Copy
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FinalMessageModal;
