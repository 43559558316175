const routes = {
  home: {
    id: 'home',
    path: '/'
  },
    template: {
    id: 'template',
    path: '/template/:templateId'
  },
  block: {
    id: 'block',
    path: '/block/:blockId'
  }
};

export default routes;
