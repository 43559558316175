import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';

//styles
import * as S from './styles';
import * as L from 'layout-styled-components';
import { useOvermind } from 'store';
import ChosenBlock from 'components/ChosenBlock';

//material ui
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import Column from 'components/Column';

const ChosenBlocks: React.FC = () => {
  const { state, actions } = useOvermind();
  const { settings, isMobile } = state;
  const { chosenBlocks } = state;

  let showAddBlock = settings.useDialogForBlocks || isMobile;

  return (
    <Column hasPadding={false}>
      <List dense component="nav">
        {chosenBlocks.map((chosen, index) => (
          <ChosenBlock key={chosen.id} index={index} block={chosen} />
        ))}
        {showAddBlock && (
          <ListItem button>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText onClick={() => actions.toggleDialog('chooseBlock')}>
              Add block
            </ListItemText>
          </ListItem>
        )}
      </List>
    </Column>
  );
};

export default SortableContainer(ChosenBlocks);
