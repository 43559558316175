import Swal from 'sweetalert2';
import { json } from 'overmind';
import axios from 'axios';

export const updateData = async store => {
  const { allBlocks, templates, app } = json(store);
  if (!app) {
    return console.error('not authenticated');
  }
  let config = {
    method: 'put',
    ...json(app.jsonBinConfig),
    data: { allBlocks, templates }
  };
  //@ts-ignore
  await axios(config);
};

export const prompt = async (
  title: string,
  inputValue?: string
): Promise<string> => {
  let settings = {
    title,
    input: 'textarea',
    ...(inputValue && { inputValue })
  };

  //@ts-ignore
  const { value } = await Swal.fire(settings);

  return value;
};

export const confirm = async (text = `You won't be able to revert this!`) => {
  const { value } = await Swal.fire({
    title: 'Are you sure?',
    text,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  });

  return value;
};

export const printMissiveLog = (...logs) => {
  const newDiv = document.createElement('div');
  newDiv.innerHTML = logs.map(l => l && l.toString()).join(' ');
  const errors = document.getElementById('errors');
  if (errors) {
    errors.appendChild(newDiv);
  }
};

export const insertInMissive = (body, reply = false) => {
  //@ts-ignore
  const Missive = window.Missive;

  try {
    if (!Missive) {
      return null;
    }
    if (reply) {
      Missive.reply({
        mailto: {
          body
        }
      });
    } else {
      Missive.insertHtml(body);
    }
  } catch (err) {
    console.log('err is', err);
    printMissiveLog(err);
  }
};
