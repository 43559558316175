import { variableRegex } from 'config/variable-regex';
import { useEffect, useRef, useState } from 'react';
import { replaceAll } from 'utils/prototypes';

export const hotkey = fn => event => {
  if (event.srcElement.tagName !== 'INPUT') {
    event.preventDefault();
    fn();
  }
};

export const getRegexMatches = (string, regexExpression, callback) => {
  let match;
  while ((match = regexExpression.exec(string)) !== null) {
    callback(match);
  }
};

export const insertIntoArray = (arr, index, item) => {
  arr.splice(index, 0, item);
};

export const highlightBlockMessage = message => {
  let newMessage = message;
  if (newMessage.includes('<a')) {
    return newMessage;
  }

  getRegexMatches(message, variableRegex, match => {
    const variable = match[1];
    let varz = match[0];

    newMessage = replaceAll(
      newMessage,
      varz,
      `<span class="block-variable">${variable}</span>`
    );
  });
  return newMessage;
};

export const includesLowercase = (a = '', b = '') =>
  a.toLowerCase().includes(b.toLowerCase());

export const useLoadScript = ({
  startLoading,
  onReady,
  onError,
  src,
  delay = 0
}) => {
  const isLoading = useRef(false);

  const [state, setState] = useState({
    ready: false,
    error: null
  });

  useEffect(() => {
    if (startLoading && isLoading.current === false) {
      setTimeout(() => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
          setState({ ready: true, error: null });
          if (onReady) {
            onReady();
          }
        };
        script.onerror = error => {
          setState({ ready: false, error });
          onError && onError();
        };
        document.body.appendChild(script);
        isLoading.current = true;
      }, delay);
    }
  }, [startLoading, isLoading.current]);

  return state;
};

export const useDocumentReady = () => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    document.addEventListener('DOMContentLoaded', () => {
      setReady(true);
    });
  }, []);
  return (
    ready ||
    (typeof document !== 'undefined' && document.readyState === 'complete')
  );
};
