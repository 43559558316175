import { TextField, Button } from '@material-ui/core';
import { users } from 'config/users';
import React, { useEffect } from 'react';
import { useInput } from 'react-hanger';
import { useOvermind } from 'store';

//styles
import * as S from './styles';
import * as L from 'layout-styled-components';

const Auth: React.FC<{ children: any }> = ({ children }) => {
  const { state, actions } = useOvermind();
  const { app, user } = state;

  const username = useInput('');
  const password = useInput('');

  const submit = () => {
    actions.login({
      username: username.value,
      password: password.value
    });
  };

  if (user && app) {
    return children;
  }

  if (user && !app) {
    return (
      <L.Vertical center style={{ margin: 'auto' }} spaceAll={15}>
        <div>
          Welcome <b>{user.name}</b>, choose an app:
        </div>
        <L.Horizontal spaceAll={15} center>
          {user.apps.map(a => (
            <Button
              variant="contained"
              color="primary"
              onClick={e => actions.setApp(a)}
            >
              {a.name}
            </Button>
          ))}
        </L.Horizontal>
      </L.Vertical>
    );
  }

  return (
    <L.Vertical center style={{ margin: 'auto' }} spaceAll={15}>
      <TextField autoFocus {...username.eventBind} placeholder="Username" />
      <TextField
        type="password"
        {...password.eventBind}
        placeholder="Password"
      />
      <Button variant="contained" color="primary" onClick={submit}>
        Log in
      </Button>
    </L.Vertical>
  );
};

export default Auth;
