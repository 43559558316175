import TextField from '@material-ui/core/TextField';
import React from 'react';
import { useInput } from 'react-hanger';
import * as L from 'layout-styled-components';

import {
  Dialog,
  ListItemText,
  ListItem,
  Divider,
  List
} from '@material-ui/core';

import { useOvermind } from 'store';
import ModalHeader from 'components/ModalHeader';
import { includesLowercase } from 'utils/utils';

const UpdateTemplateModal: React.FC<{ open?: boolean; onClose?: any }> = ({
  open,
  onClose
}) => {
  const { state, actions } = useOvermind();
  const { templates } = state;
  const search = useInput('');

  const filteredTemplates = templates.filter(t =>
    includesLowercase(t.title, search.value)
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      scroll="paper"
      onClose={onClose}
      open={open}
    >
      <ModalHeader id="customized-dialog-title" onClose={onClose}>
        Choose template
      </ModalHeader>
      <TextField
        {...search.eventBind}
        style={{ padding: 15 }}
        autoFocus
        placeholder="Search..."
      />
      <List>
        {filteredTemplates.map((template, index) => (
          <>
            <ListItem
              onClick={() => actions.chooseTemplateToUpdate(template)}
              button
            >
              <L.Horizontal fullW centerV>
                <L.Vertical flex={1}>
                  <ListItemText
                    primary={template.title}
                    secondary={template.description}
                  />
                </L.Vertical>
              </L.Horizontal>
            </ListItem>
            {index !== templates.length - 1 && <Divider />}
          </>
        ))}
      </List>
    </Dialog>
  );
};

export default UpdateTemplateModal;
