import React, { useState } from 'react';
import RichTextEditor from 'react-rte';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';

import { useOvermind } from 'store';

//components
import ModalHeader from 'components/ModalHeader';
import {replaceAll} from "utils/prototypes";

const EditBlockModal: React.FC<{ open?: boolean; onClose?: any }> = ({
  open,
  onClose
}) => {
  const { state, actions } = useOvermind();
  const { editingBlockInDialog } = state;

  const [editorState, setEditorState] = useState(() =>
    RichTextEditor.createValueFromString(
      editingBlockInDialog ? editingBlockInDialog.message : '',
      'html'
    )
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      scroll="paper"
      onClose={onClose}
      open={open}
    >
      <ModalHeader id="customized-dialog-title" onClose={onClose}>
        {editingBlockInDialog ? 'Edit block' : 'Create a block'}
      </ModalHeader>
      <DialogContent>
        <RichTextEditor
          autoFocus
          value={editorState}
          onChange={setEditorState}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            let html = editorState.toString('html');

            if (editingBlockInDialog) {
              actions.editBlock({
                blockId: editingBlockInDialog.id,
                message: html
              });
            } else {
              actions.addBlock({
                message: html
              });
            }
          }}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditBlockModal;
