import React from 'react';

import * as L from 'layout-styled-components';
import * as A from 'styles/shared-components';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  TextField
} from '@material-ui/core';

import { useOvermind } from 'store';
import { getFinalMessage } from 'store/helpers';
import FinalMessage from 'components/FinalMessage';
import TemplatesList from 'components/TemplatesList';
import FillTemplate from 'components/FillTemplate';
import ModalHeader from 'components/ModalHeader';
import { flex } from 'layout-styled-components';

const useStyles = makeStyles(() => ({
  scrollPaper: {
    minHeight: '80vh'
  }
}));

const ChooseTemplateModal: React.FC<{ open?: boolean; onClose?: any }> = ({
  open,
  onClose
}) => {
  const classes = useStyles({});
  const { state, actions } = useOvermind();
  const { selectedTemplate: template, hasTemplateVariables, isMobile } = state;

  const templateFinalMessage = template
    ? getFinalMessage({
        chosenBlocks: template.blocks,
        allBlocks: state.allBlocks,
        settings: state.settings
      })
    : '';

  return (
    <Dialog
      fullScreen
      disableEnforceFocus
      classes={{
        paper: classes.scrollPaper
      }}
      fullWidth
      maxWidth="xl"
      onClose={onClose}
      open={open}
    >
      <ModalHeader id="customized-dialog-title" onClose={onClose}>
        Choose a template
      </ModalHeader>
      <DialogContent
        style={{
          ...flex.vertical,
          backgroundColor: '#efefef',
          overflow: 'hidden',
          height: '100%'
        }}
        dividers
      >
        {isMobile && template && (
          <>{hasTemplateVariables && <FillTemplate />}</>
        )}
        {isMobile && (
          <L.Vertical>
            <FinalMessage finalMessage={templateFinalMessage} />
          </L.Vertical>
        )}
        {isMobile && !template && (
          <L.Vertical fullH flex={1}>
            <TextField
              style={{ width: '100%' }}
              autoFocus={true}
              placeholder="Search templates..."
              value={state.searchTemplates}
              onChange={e => actions.setSearchTemplates(e.target.value)}
            />
            <L.Space size={10} />
            <TemplatesList />
          </L.Vertical>
        )}

        {!isMobile && (
          <A.Grid
            gridTemplateColumns="350px 150px 1fr"
            gridTemplateRows="100%"
            gridGap={25}
            minColumnWidth={300}
            style={{
              flex: 1,
              height: '100%',
              overflow: 'hidden'
            }}
          >
            <L.Vertical>
              <TextField
                style={{ width: '100%' }}
                autoFocus={true}
                placeholder="Search templates..."
                value={state.searchTemplates}
                onChange={e => actions.setSearchTemplates(e.target.value)}
              />
              <L.Space size={10} />
              <TemplatesList />
            </L.Vertical>
            {template && (
              <>
                {hasTemplateVariables && <FillTemplate />}
                <L.Vertical
                  style={{
                    border: '1px solid #c1c1c1',
                    padding: 10,
                    borderRadius: 5,
                    backgroundColor: 'white'
                  }}
                  fullH
                  flex={1}
                >
                  <FinalMessage finalMessage={templateFinalMessage} />
                </L.Vertical>
              </>
            )}
          </A.Grid>
        )}
      </DialogContent>
      <DialogActions>
        {template && (
          <L.Horizontal spaceBetween fullW>
            <L.Horizontal>
              <Button onClick={actions.deleteChosenTemplate} color="secondary">
                Delete
              </Button>
              <Button onClick={actions.editTemplate}>Edit</Button>
              {isMobile && (
                <Button onClick={actions.clearChosenTemplate}>Cancel</Button>
              )}
            </L.Horizontal>
            <L.Horizontal>
              <Button
                onClick={() => actions.copyTemplate(templateFinalMessage)}
                color="primary"
              >
                Copy
              </Button>
              <Button
                onClick={() => actions.chooseTemplate(template)}
                color="primary"
              >
                Choose
              </Button>
              <Button
                onClick={() =>
                  actions.insertChosenTemplateInMissive({
                    message: templateFinalMessage,
                    reply: true
                  })
                }
                color="primary"
              >
                Reply
              </Button>
              <Button
                onClick={() =>
                  actions.insertChosenTemplateInMissive({
                    message: templateFinalMessage,
                    reply: false
                  })
                }
                color="primary"
              >
                Insert
              </Button>
            </L.Horizontal>
          </L.Horizontal>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ChooseTemplateModal;
