import styled from 'styled-components';
import { Horizontal, flex } from 'layout-styled-components';
export const App = styled.div({
  ...flex.vertical,
  flex: 1,
  width: '100%'
});

export const Wrap = styled.div({
  ...flex.vertical,
  flex: 1,
  padding: 20,
  height: '100%'
});

export const ColumnHeader = styled(Horizontal)({
  height: 30
});

ColumnHeader.defaultProps = {
  centerV: true,
  fullW: true,
  spaceAll: 10
};

export const Search = styled.input({
  outline: 'none',
  borderRadius: 5,
  border: '1px solid #ffffff',
  padding: 5,
  flex: 1
});
