import styled from 'styled-components';

export const Block = styled.div({
  color: '#505050',
  fontSize: 16,
  lineHeight: '22px',
  '& p': {
    margin: '3px 0'
  }
});
