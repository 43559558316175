import React from 'react';
import SanitizedHTML from 'react-sanitized-html';

//styles
import * as S from './styles';
import * as L from 'layout-styled-components';
import { copyToClipboard } from 'copy-lite';
import { sanitizeHtmlConfig } from 'config/sanitize-html-config';

const FinalMessage: React.FC<{ finalMessage: string }> = ({ finalMessage }) => {
  return (
    <S.FinalMessage onClick={() => copyToClipboard(finalMessage, true)}>
      <SanitizedHTML
        {...sanitizeHtmlConfig}
        html={finalMessage}
      />
    </S.FinalMessage>
  );
};

export default FinalMessage;
