import { sanitizeHtmlConfig } from 'config/sanitize-html-config';
import { Block, BlockValues, ChosenBlock, State } from 'store/state';
import { replaceAll } from 'utils/prototypes';
import sanitize from 'sanitize-html';

export const replaceBlockVariables = (
  chosenBlock: ChosenBlock,
  foundBlock: Block,
  values: BlockValues
) => {
  if (!chosenBlock) {
    return '';
  }

  if (chosenBlock.message) {
    return chosenBlock.message;
  }

  if (!foundBlock) {
    return '';
  }

  let final = foundBlock.message;
  const { variables = [] } = foundBlock;
  variables.forEach(variable => {
    let foundValue = values.find(val => val.id === variable.id);
    let replacement = foundValue ? foundValue.value : '';
    final = replaceAll(final, `{{${variable.id}}}`, replacement);
  });

  return final;
};

const removeParagraphs = message => {
  let newMessage = replaceAll(message, '<p>', '');
  newMessage = replaceAll(newMessage, '</p>', '');
  return newMessage;
};

export const getFinalMessage = ({
  chosenBlocks,
  allBlocks,
  settings
}: Partial<State>): string => {
  let finalMessage = chosenBlocks.map(chosenBlock => {
    const foundBlock = allBlocks.find(b => b.id === chosenBlock.blockId);
    let blockWithReplaced = replaceBlockVariables(
      chosenBlock,
      foundBlock,
      chosenBlock.values
    );

    let lastChar = blockWithReplaced[blockWithReplaced.length - 1];
    const validLastChars = ['.', '!', ',', ':'];

    if (
      blockWithReplaced !== '' &&
      settings.autoDot === true &&
      !validLastChars.includes(lastChar)
    ) {
      blockWithReplaced = `${blockWithReplaced}.`;
    }

    return blockWithReplaced;
  });
  let joined = finalMessage.join('<br/><br/>');
  return sanitize(joined, sanitizeHtmlConfig);
};

export const compareFuzzy = (a = '', b = '') => {
  const chopped = a.toLowerCase().split(' ');
  return chopped.every(c => b.toLowerCase().includes(c));
};

export const searchBlocks = ({
  blocks,
  chosenBlocks,
  search
}: {
  blocks: Block[];
  chosenBlocks: ChosenBlock[];
  search: string;
}): Block[] => {
  return blocks.filter(block => {
    //if block already chosen, don't show it
    if (chosenBlocks.find(b => b.blockId === block.id)) {
      return false;
    }
    return [block.message, block.label, block.id].some((prop = '') => {
      return compareFuzzy(search, prop);
    });
  });
};
