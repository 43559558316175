import { compareFuzzy, getFinalMessage, searchBlocks } from 'store/helpers';
import map from 'lodash/map';
import {apps, LazyApp, LazyUser, users} from 'config/users';

export type Variable = {
  id: string;
  label: string;
};

export type Block = {
  message: string;
  label?: string;
  variables?: Variable[];
  id: string;
};

export type VariableValue = {
  id: string;
  value: string;
};

export type BlockValues = VariableValue[];

export type ChosenBlock = {
  id: string;
  values?: BlockValues;
  blockId?: string;
  message?: string;
};

export type Settings = {
  autoCapitalize?: boolean;
  autoDot?: boolean;
  autoSpace?: boolean;
  spaceHtml?: string;
  useDialogForBlocks?: boolean;
  limitBlocks?: boolean;
  inverseBlocks?: boolean;
};

export type Template = {
  id: string;
  title: string;
  description?: string;
  tags?: string[];
  blocks: ChosenBlock[];
  favorite?: boolean;
};

type Dialogs = {
  chooseTemplate: boolean;
  updateTemplate: boolean;
  finalMessage: boolean;
  chooseBlock: boolean;
};

export type Entity = {
  id: string;
  name: string;
  value: any;
};

export type EntityGroup = {
  id: string;
  name: string;
  entries: Entity[];
};

export type State = {
  app?: LazyApp;
  user?: LazyUser;
  count: number;
  search: string;
  searchTemplates: string;
  hasTemplateVariables: boolean;
  creatingBlock: boolean;
  chosenBlocks: ChosenBlock[];
  allBlocks: Block[];
  filteredBlocks: Block[];
  filteredTemplates: Template[];
  settings: Settings;
  templates: Template[];
  dialogs: Dialogs;
  editingBlock?: ChosenBlock;
  editingBlockInDialog?: Block;
  insertingBlock?: Block;
  blockForm: any;
  blockFormValues: any;
  selectedTemplate?: Template;
  hasChosenBlocks: boolean;
  isMobile: boolean;
  drawer: boolean;
  selectedTemplateVariables: VariableValue[];
  entityGroups: EntityGroup[];
  missiveLogs: string[];
  previewText?: string;
  readonly finalMessage: string;
};

export const state: State = {
  allBlocks: [],
  missiveLogs: [],
  user: users.find(u => u.username === window.localStorage.getItem('username')),
  app: apps.find(a => a.id === window.localStorage.getItem('appId')),
  count: 0,
  search: '',
  searchTemplates: '',
  templates: [],
  selectedTemplate: null,
  isMobile: window.innerWidth < 700,
  editingBlock: null,
  editingBlockInDialog: null,
  blockForm: {},
  drawer: false,
  creatingBlock: false,
  settings: {
    autoCapitalize: true,
    autoDot: false,
    autoSpace: true,
    spaceHtml: '<br/><br/>',
    useDialogForBlocks: false,
    limitBlocks: true,
    inverseBlocks: true,
    ...(JSON.parse(window.localStorage.getItem('settings')) as Settings)
  },
  dialogs: {
    chooseTemplate: false,
    updateTemplate: false,
    finalMessage: false,
    chooseBlock: false
  },
  chosenBlocks: [],
  entityGroups: [
    {
      id: 'links',
      name: 'Links',
      entries: [
        {
          id: 'download',
          name: 'Download',
          value: `https://sizzy.co/download`
        },
        {
          id: 'license',
          name: 'License Manager',
          value: `https://sizzy.co/license`
        },
        {
          id: 'help-center',
          name: 'Help Center',
          value: `https://www.notion.so/sizzy/Sizzy-Help-Center-70c43d8a9c6148d2b63c66f9e57b255c`
        },
        {
          id: 'update',
          name: 'Update',
          value: `https://www.notion.so/sizzy/How-to-update-to-the-latest-version-354038a3042d4e9da9e9b66ac2f89a29://sizzy.co/help`
        }
      ]
    },
    {
      id: 'links',
      name: 'Coupon codes',
      entries: [
        {
          id: 'charity',
          name: 'Charity',
          value: 'CHARITYROCKS'
        },
        {
          id: 'student',
          name: 'Student',
          value: 'STUDENTROCKS'
        }
      ]
    }
  ],
  // get allBlocks(this: State) {
  //   return this._allBlocks.map(b => {
  //     let newMessage = replaceAll(b.message, '<p>', '');
  //     newMessage = replaceAll(newMessage, '</p>', '');
  //     newMessage = replaceAll(newMessage, '<br/>', '');
  //     newMessage = replaceAll(newMessage, '<br>', '');
  //     return {
  //       ...b,
  //       message: newMessage
  //     };
  //   });
  // },
  get finalMessage(this: State) {
    return getFinalMessage(this);
  },
  get hasChosenBlocks(this: State) {
    return this.chosenBlocks.length > 0;
  },
  get filteredBlocks(this: State) {
    let results = searchBlocks({
      blocks: this.allBlocks,
      chosenBlocks: this.chosenBlocks,
      search: this.search
    });
    return this.settings.inverseBlocks ? results.reverse() : results;
  },
  get filteredTemplates(this: State) {
    return this.templates.filter(t => {
      let searchText = this.searchTemplates || '';
      const nameMatches = compareFuzzy(searchText, t.title);
      const blocksMatch = t =>
        t.blocks.some(block => {
          const originalBlock = this.allBlocks.find(b => b.id === block.id);
          return (
            originalBlock && compareFuzzy(searchText, originalBlock.message)
          );
        });
      const rules = [nameMatches];
      return rules.some(r => !!r);
    });
  },
  get blockFormValues(this: State) {
    return map(this.blockForm, (value, id) => ({
      value,
      id
    }));
  },
  get selectedTemplateVariables(this: State) {
    if (!this.selectedTemplate) {
      return [];
    }
    let variables = this.selectedTemplate.blocks.reduce((a, b) => {
      a = [...a, ...b.values];
      return a;
    }, []);

    return variables;
  },
  get hasTemplateVariables(this: State) {
    return this.selectedTemplateVariables.length > 0;
  }
};
