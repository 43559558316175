import React, { Fragment } from 'react';
import { orderBy } from 'lodash';
import { getFinalMessage } from 'store/helpers';

//styles
import * as S from './styles';
import * as L from 'layout-styled-components';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  Tooltip
} from '@material-ui/core';
import { Link, Favorite, RemoveRedEye as Eye } from '@material-ui/icons';
import { useOvermind } from 'store';

const TemplatesList: React.FC<{ onChoose?: any }> = ({ onChoose }) => {
  const { state, actions } = useOvermind();
  const { filteredTemplates: templates, selectedTemplate } = state;

  let orderedTemplates = orderBy(
    templates,
    template => template.favorite === true,
    ['desc']
  );

  return (
    <S.TemplatesList>
      <List disablePadding={true}>
        {orderedTemplates.map(template => {
          return (
            <Fragment key={template.id}>
              <ListItem
                style={{
                  ...(selectedTemplate &&
                    selectedTemplate.id === template.id && {
                      backgroundColor: 'white'
                    })
                }}
                button
                onClick={() =>
                  onChoose
                    ? onChoose(template)
                    : actions.selectTemplate(template)
                }
              >
                <L.Horizontal centerV spaceAll={10} flex={1}>
                  <Tooltip title="Toggle favorite">
                    <IconButton
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        actions.toggleTemplateFavorite(template);
                      }}
                      size="small"
                    >
                      <Favorite
                        color={template.favorite ? 'secondary' : 'inherit'}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Copy link">
                    <IconButton
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        actions.copyLinkToTemplate(template);
                      }}
                      size="small"
                    >
                      <Link fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Preview">
                    <IconButton
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        const preview = getFinalMessage({
                          chosenBlocks: template.blocks,
                          allBlocks: state.allBlocks,
                          settings: state.settings
                        });
                        actions.showPreviewDialog(preview);
                      }}
                      size="small"
                    >
                      <Eye fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <div>{template.title}</div>
                </L.Horizontal>
              </ListItem>
              <Divider />
            </Fragment>
          );
        })}
      </List>
    </S.TemplatesList>
  );
};

export default TemplatesList;
