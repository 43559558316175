import React from 'react';

import * as L from 'layout-styled-components';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core';

import { useOvermind } from 'store';
import ModalHeader from 'components/ModalHeader';

const InsertBlockModal: React.FC<{ open?: boolean; onClose?: any }> = ({
  open,
  onClose
}) => {
  const { state, actions } = useOvermind();
  const { blockForm, editingBlock, insertingBlock } = state;

  const onDone = () => {
    if (editingBlock) {
      actions.updateChosenBlock(editingBlock.id);
    } else {
      actions.insertChosenBlock(insertingBlock.id);
    }
  };

  return (
    <Dialog fullWidth maxWidth="xl" onClose={onClose} open={open}>
      {open && (
        <>
          <ModalHeader id="customized-dialog-title" onClose={onClose}>
            {editingBlock ? 'Edit block variables' : 'Insert a block'}
          </ModalHeader>
          <DialogContent dividers>
            {insertingBlock.variables.map((variable, index) => {
              let value = blockForm[variable.id];
              const isValid = value && value.trim() !== '';
              return (
                <div key={variable.id}>
                  <TextField
                    label={variable.label}
                    onKeyPress={ev => {
                      if (ev.key === 'Enter' && isValid) {
                        ev.preventDefault();
                        onDone();
                      }
                    }}
                    value={value}
                    onChange={e =>
                      actions.setBlockFormValue({
                        id: variable.id,
                        value: e.target.value
                      })
                    }
                    autoFocus={index === 0}
                    placeholder={variable.label}
                  />
                </div>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={onDone} color="primary">
              Done
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default InsertBlockModal;
