import React, { useMemo } from 'react';
import SanitizedHTML from 'react-sanitized-html';
//styles
import * as L from 'layout-styled-components';
import * as S from './styles';
//material
import { Divider, ListItem } from '@material-ui/core';

import { Block as TBlock } from 'store/state';
import { useOvermind } from 'store';
import IconButton from '@material-ui/core/IconButton';
import { ChevronRight, Delete, Edit } from '@material-ui/icons';
import { sanitizeHtmlConfig } from 'config/sanitize-html-config';
import { highlightBlockMessage } from 'utils/utils';

const Block: React.FC<{
  block: TBlock;
  showDivider?: boolean;
  allowEdit?: boolean;
  asButton?: boolean;
}> = ({ block, allowEdit = true, asButton, showDivider }) => {
  const { actions } = useOvermind();
  let blockMessage = useMemo(() => highlightBlockMessage(block.message), [
    block.message
  ]);

  return (
    <>
      <ListItem
        {...(asButton && {
          button: true,
          onClick: e => {
            actions.addChosenBlock(block);
            if (e.metaKey) {
              actions.focusSearch({});
            } else {
              actions.toggleDialog('chooseBlock');
            }
          }
        })}
      >
        <L.Horizontal fullW centerV>
          {allowEdit && (
            <>
              <IconButton
                size="small"
                onClick={() => {
                  actions.deleteBlock(block);
                }}
              >
                <Delete fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  actions.editBlockInDialog(block);
                }}
              >
                <Edit fontSize="small" />
              </IconButton>
              <L.Space size={10} />
            </>
          )}
          <L.Vertical flex={1}>
            <div style={{ fontWeight: 500 }}> {block.label}</div>
            <S.Block>
              {<SanitizedHTML {...sanitizeHtmlConfig} html={blockMessage} />}
            </S.Block>
          </L.Vertical>
          {allowEdit && (
            <IconButton
              onClick={() => {
                actions.addChosenBlock(block);
              }}
            >
              <ChevronRight />
            </IconButton>
          )}
        </L.Horizontal>
      </ListItem>
      {showDivider && <Divider />}
    </>
  );
};

export default Block;
