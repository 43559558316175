import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/App';
import 'utils/prototypes';
import 'modern-normalize/modern-normalize.css';
import 'styles/styles.css';
import { createOvermind } from 'overmind';
import { config } from './store';
import { Provider } from 'overmind-react';
import { Router } from 'react-tiniest-router';
import routes from 'config/routes';
import Auth from 'components/Auth';

const overmind = createOvermind(config);

const rootElement = document.getElementById('root');
ReactDOM.render(
  <Router routes={routes}>
    <Provider value={overmind}>
      <Auth>
        <App />
      </Auth>
    </Provider>
  </Router>,
  rootElement
);
