import styled from 'styled-components';
import { flex } from 'layout-styled-components';
import * as A from 'styles/shared-components';
import * as L from 'layout-styled-components';

export const DividerContent = styled(L.Horizontal)({
  transition: 'all 100ms linear',
  height: '100%'
});

export const Divider = styled.div({
  height: 1,
  overflow: 'hidden',
  backgroundColor: '#e7e7e7',
  width: '100%',
  cursor: 'pointer',
  transition: 'all 100ms linear'
});

export const ChosenBlock = styled.div({
  cursor: 'grab',
  userSelect: 'none'
});

export const MiniButton = styled.div({
  fontSize: 10,
  transition: 'all 100ms linear',
  padding: '3px 5px',
  borderRadius: 3,
  cursor: 'pointer',
  backgroundColor: '#d6d6d6',
  '&:hover': {
    backgroundColor: '#cbcbcb'
  }
});
