import React, { useEffect } from 'react';

//styles
import * as S from './styles';
import * as L from 'layout-styled-components';
import { TextField } from '@material-ui/core';
import { useOvermind } from 'store';

const FillTemplate: React.FC = () => {
  const { actions, state } = useOvermind();
  const { selectedTemplateVariables, selectedTemplate } = state;

  useEffect(() => {
    selectedTemplateVariables.forEach(variable => {
      actions.updateVariableInTemplate({
        id: variable.id,
        value: ''
      });
    });
  }, [selectedTemplate.id]);

  return (
    <L.Vertical>
      <L.Space size={5} />
      {selectedTemplateVariables.map((variable, index) => {
        return (
          <div>
            <TextField
              label={variable.id}
              style={{ width: '100%' }}
              onKeyPress={ev => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                }
              }}
              value={variable.value}
              onChange={e => {
                actions.updateVariableInTemplate({
                  id: variable.id,
                  value: e.target.value
                });
              }}
              autoFocus={index === 0}
              placeholder={variable.id}
            />
            <L.Space size={20} />
          </div>
        );
      })}
    </L.Vertical>
  );
};

export default FillTemplate;
